import React from "react"
// import { Link } from "gatsby"

import SEO from "../components/seo"
import IndexHeader from "../components/index-header"
import FeaturedIn from "../components/blocks/featured_in"
import Testimonials from "../components/blocks/testimonials"
import Applications from "../components/blocks/applications"
import LearnMore from "../components/blocks/learn-more"
import Supporters from "../components/blocks/supporters"
import HomeText from "../components/blocks/home-text"
import HomeImages from "../components/blocks/home-images"
import Footer from "../components/footer"
import HeadAssets from "../components/head-assets"

const IndexPage = () => (
  <>
      <SEO title="Accueil KAATSU France" />
      <HeadAssets/>
      <IndexHeader/>
      <HomeText/>
      <div className="bg-dark-red" style={{position: "relative",zIndex: 2, minHeight: '30vh', top: 0, marginBottom: '-30vh', clipPath: 'polygon(0 0, 100% 0, 100% 60%, 0 30%)'}}></div>
      <div className="container my-5" style={{paddingTop: '15vh'}}>
          <HomeImages/>
          <FeaturedIn/>
      </div>
      <div id="testimonials-container">
        <div className="bg-default"></div>
        <div style={{position: 'relative', zIndex: 3, paddingBottom: '10vh'}}>
          <Testimonials/>
          <Applications/>
        </div>
      </div>
      <LearnMore/>
      <Supporters/>
      <Footer/>
  </>
)

export default IndexPage
