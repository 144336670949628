import Menu from "./menu"
import React from "react"
import l from '../lang/lang'

const IndexHeader = () => (
	<div className="bg-red">
		<div id="hero" className="m-0 p-0">
			<div className="container-fluid p-md-5 text-white">
				<div className="container-fluid px-md-5">
					<nav className="navbar navbar-expand-lg navbar-dark mb-5">
						<Menu home={true}/>
					</nav>
				</div>
				<div className="d-none d-md-block">
					<div className="p-5">
						<h1 className="display-3 font-weight-bolder">
							{l('RECOVER FASTER')}
							<br/>{l('REHAB STRONGER')}
							<br/>{l('PERFORM BETTER')}
						</h1>
						<h1 className="serif-font mb-5">{l('KAATSU - The Original BFR')}</h1>
					</div>
				</div>
				<div className="d-block d-md-none">
					<div className="p-2">
						<h4 className="font-weight-bolder">
							RECOVER FASTER.
							<br/>REHAB STRONGER.
							<br/>PERFORM BETTER.
						</h4>
						<strong className="serif-font">KAATSU - The Original BFR</strong>
					</div>
				</div>
			</div>
		</div>
	</div>
)

export default IndexHeader